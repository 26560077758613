<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name; delete serverErrors.slug}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name||serverErrors.slug"
                    data-cy="name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.rfc = v; delete serverErrors.rfc; delete serverErrors.slug}" 
                    :valueInput="values.rfc" 
                    :rule="[...rules.rfc]" 
                    label="RFC"
                    :errorMessages="serverErrors.rfc||serverErrors.slug"
                    data-cy="rfc-input"
                    :counter="13"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.business_name = v; delete serverErrors.business_name; delete serverErrors.slug}" 
                    :valueInput="values.business_name" 
                    :rule="[...rules.required]" 
                    label="Razón social"
                    :errorMessages="serverErrors.business_name||serverErrors.slug"
                    data-cy="business_name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <addressCodeSelector
                    @valueChange="(v) => {values.address_code = v; delete serverErrors.address_code}" 
                    @stateChange="(v) => {values.state = v.state; values.state_id = v.state_id}"
                    @cityChange="(v) => {values.city = v.city}"
                    @countryChange="(v) => {values.country = v.country; values.country_id = v.country_id}"
                    :valueInput="values.address_code" 
                    :rule="[...rules.required]" 
                    :serverErrors="serverErrors"
                    :errorMessages="serverErrors.address_code"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.neighborhood = v; delete serverErrors.neighborhood}" 
                    :valueInput="values.neighborhood" 
                    :rule="[...rules.required]" 
                    label="Colonia"
                    :errorMessages="serverErrors.neighborhood"
                    data-cy="neighborhood-input"
                ></textFieldForm>
            </v-col>
        </v-row>


         <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.street = v; delete serverErrors.street}" 
                    :valueInput="values.street" 
                    :rule="[...rules.required]" 
                    label="Calle"
                    :errorMessages="serverErrors.street"
                    data-cy="street-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.ext_number = v; delete serverErrors.ext_number}" 
                    :valueInput="values.ext_number" 
                    :rule="[...rules.required]" 
                    label="No. Exterior"
                    :errorMessages="serverErrors.ext_number"
                    data-cy="ext_number-input"
                ></textFieldForm>
            </v-col>

            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.int_number = v; delete serverErrors.int_number}" 
                    :valueInput="values.int_number" 
                    :rule="[]" 
                    label="No. Interior"
                    :errorMessages="serverErrors.int_number"
                    data-cy="int_number-input"
                ></textFieldForm>
            </v-col>
        </v-row>


         <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.contact = v; delete serverErrors.contact; delete serverErrors.slug}" 
                    :valueInput="values.contact" 
                    :rule="[...rules.required]" 
                    label="Contacto"
                    :errorMessages="serverErrors.contact||serverErrors.slug"
                    data-cy="contact-input"
                ></textFieldForm>
            </v-col>
        </v-row>

         <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.phone = v; delete serverErrors.phone}" 
                    :valueInput="values.phone" 
                    :rule="[...rules.phone]" 
                    label="Teléfono"
                    :errorMessages="serverErrors.phone"
                    data-cy="phone-input"
                    :counter="10"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.email = v; delete serverErrors.email; delete serverErrors.slug}" 
                    :valueInput="values.email" 
                    :rule="[...rules.email]" 
                    label="Correo Electrónico"
                    :errorMessages="serverErrors.email||serverErrors.slug"
                    data-cy="email-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                <p class="mb-0 pb-0">Proveedor de:</p>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col class="" >
                <div class="bill-mark-all-cont">
                    
                    <checkboxForm
                        label="Materia prima"
                        :valueInput="values.supplies_materia"
                        
                        @valueChange="(v) => {values.supplies_materia = v;  delete serverErrors[`supplies`];}"
                        :errorMessages="serverErrors[`supplies`]"
                        data-cy="supplies_materia-input"
                    ></checkboxForm>
                </div>
            </v-col>
             <v-col class="" >
                <div class="bill-mark-all-cont">
                    
                    <checkboxForm
                        label="Consumibles"
                        :valueInput="values.supplies_consumable"
                        
                        @valueChange="(v) => {values.supplies_consumable = v;  delete serverErrors[`supplies_consumable`];}"
                        :errorMessages="serverErrors[`supplies`]"
                        data-cy="supplies_consumable-input"
                    ></checkboxForm>
                </div>
            </v-col>
             <v-col class="" >
                <div class="bill-mark-all-cont">
                    
                    <checkboxForm
                        label="Indirectos"
                        :valueInput="values.supplies_indirect"
                        
                        @valueChange="(v) => {values.supplies_indirect = v;  delete serverErrors[`supplies_indirect`];}"
                        :errorMessages="serverErrors[`supplies`]"
                        data-cy="supplies_indirect-input"
                    ></checkboxForm>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="error--text">
                {{serverErrors[`supplies`]}}
            </v-col>
        </v-row>



       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                rfc: [
                    // v => !!v || 'El campo requerido',
                    v => (v.length == 0 || (v.length >= 12  && v.length <= 13)) || 'El campo debe contener de 12 a 13 carácteres'
                ],
                phone: [
                    // v => !!v || 'Teléfono es requerido',
                    v => /^[0-9]*$/.test(v) || 'El campo sólo debe contener números',
                    v => (v.length == 0 || v.length == 10) || 'El campo debe contener 10 digitos'
                ],
                email: [
                    (v) => {
                        if(v == "")
                            return true;
                        const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(v) || "Correo no válido";
                    },
                ],
				
				
            },

            

          
        }
    },
   
    mounted(){
    },
    methods: {
        
       
    }
}
</script>

<style>

</style>